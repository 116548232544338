/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./assets/fonts/brandon/fonts";
@import "./assets/fonts/benton/fonts";
@import "./assets/fonts/avenir_next/fonts";
@import "./assets/fonts/poppins_extra_bold/fonts";
@import "./assets/fonts/futura/fonts";
@import "./assets/fonts/flame/fonts";
@import "styles/buttons.scss";
@import "styles/layouts.scss";
@import "styles/inputs.scss";
@import "styles/swiper.scss";
$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$text-color: var(--text-color);
$detail-color: var(--detail-color);
$background-image: var(--background-image);
$font-family: var(--font-family);
$wink-color: #252ded;
$measure: var(--measure);
:root {
  --measure: 100vh;
}
body,
html {
  width: 100%;
  height: calc($measure - 1px);
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  display: none;
}
app-root {
  @apply h-full w-full bg-[whitesmoke];
  //   background-image: $background-image;
  //   background-size: cover;
  //   background-repeat: no-repeat;
  font-family: $font-family;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  height: 100%;
  & > * {
    @apply h-full w-full;
  }
}
router-outlet ~ * {
  @apply page;
}
.wink-background {
  background-color: $wink-color;
}

.real-h {
  height: $measure;
}

.wink-fill {
  fill: $wink-color;
}

.wink-text {
  color: $wink-color;
}

.wink-border {
  border-color: $wink-color;
}

//colores del negocio para fondo
.bg-primary {
  background-color: $primary-color;
}

.border-primary {
  border-color: $primary-color;
}

.bg-secondary {
  background-color: $secondary-color;
}
.bg-accent {
  background-color: $text-color !important;
}
.bg-detail {
  background-color: $detail-color;
}
.bg-image {
  background-image: $background-image;
}
//colores del negocio para textos
.text-primary {
  color: $primary-color;
  accent-color: $primary-color;
}
.text-secondary {
  color: $secondary-color;
}
.text-accent {
  color: $text-color;
}
.text-detail {
  color: $detail-color;
}
//font-family del negocio
.font-primary {
  font-family: $font-family;
}
.border-primary {
  border-color: $primary-color;
}
.border-secondary {
  border-color: $secondary-color;
}
.border-accent {
  border-color: $text-color;
}
.border-detail {
  border-color: $detail-color;
}
.shadow-primary {
  box-shadow: $primary-color;
}
.shadow-secondary {
  box-shadow: $secondary-color;
}
.shadow-accent {
  box-shadow: $text-color;
}
.shadow-detail {
  box-shadow: $detail-color;
}

.fill-detail {
  fill: $detail-color;
}
.fill-secondary {
  fill: $secondary-color;
}
.fill-primary {
  fill: $primary-color;
}
.fill-black {
  fill: black;
}
.fill-white {
  fill: white;
}
.separator::before {
  @apply border-primary;
}
.separator::after {
  @apply border-primary;
}

.outline-accent {
  outline-color: $text-color;
}

.outline-primary {
  outline-color: $primary-color;
}

// OVERRIDE MATERIAL

// .mat-form-field-flex {
//   font-family: var(--font-family);
//   border-top-left-radius: 0.5rem !important;
//   border-top-right-radius: 0.5rem !important;
//   border-bottom-left-radius: 0.5rem !important;
//   border-bottom-right-radius: 0.5rem !important;
//   padding-top: 0 !important;
//   padding-bottom: 0 !important;
//   background-color: white !important;
//   @apply base-input;
// }

// .mat-form-field-infix {
//   width: 110px !important;
// }
// .mat-form-field-appearance-fill .mat-form-field-infix {
//   padding: 0 !important;
//   padding-bottom: 0.5rem !important;
// }
// .mat-form-field-wrapper {
//   padding-bottom: 0 !important;
// }
// .mat-form-field-underline {
//   display: none;
// }
