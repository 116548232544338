@font-face {
  font-family: "Benton Sans";
  src: url("./book/BENTONSANS-BOOK.OTF") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Benton Sans";
  src: url("./bold/BENTONSAN-BOLD.OTF") format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
