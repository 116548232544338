//OVERRIDE SWIPER JS
.swiper-button-next {
  @apply text-primary;
}
.swiper-button-prev {
  @apply text-primary;
}
swiper {
  padding-bottom: 30px !important;
  position: relative !important;
}
.swiper-pagination {
  position: absolute !important;
  bottom: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  .swiper-pagination-bullet-active {
    @apply bg-primary;
  }
  .swiper-pagination-bullet {
    height: 15px !important;
    width: 15px !important;
  }
}
