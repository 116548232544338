.select-wrapper {
  @apply relative;
  &::after {
    font: var(--fa-font-solid);
    content: "\f078";
    font-size: 1rem;
    top: 50%;
    transform: translate(0%, -50%);
    right: 10px;
    position: absolute;
  }
  select {
    //styling general para todos los botones
    @apply cursor-pointer rounded-lg py-2 px-4 appearance-none outline-none;
  }
  .base-select {
    @apply cursor-pointer rounded-tl-lg rounded-bl-lg rounded-tr-none rounded-br-none py-2 px-4 appearance-none outline-none;
  }
}

.input {
  @apply py-2 px-4 appearance-none outline-none border-secondary border-b-2 border-solid;
}

.base-input {
  @apply appearance-none bg-white rounded-lg py-2 px-4 w-full border-slate-400 border focus-visible:outline-blue-500;
}

.base-doc-input {
  @apply appearance-none bg-white rounded-tr-lg rounded-br-lg py-2 px-4 w-full border-slate-400 border;
}

.search-wrapper {
  @apply relative w-full;
  &::before {
    font: var(--fa-font-solid);
    content: "\f002";
    font-size: 1rem;
    top: 50%;
    transform: translate(0%, -50%);
    left: 10px;
    position: absolute;
  }
}
.radio-form-control {
  @apply grid items-center justify-center;
  input[type="radio"] {
    @apply before:box-border after:box-border appearance-none bg-[whitesmoke] m-0 text-primary w-5 h-5 border-[0.05em] transition transform
   border-solid border-primary rounded-full grid place-content-center;
    &::before {
      content: "";
      @apply w-[0.5em] h-[0.5em] rounded-full scale-0 transition transform shadow-primary;
      // box-shadow: inset 1em 1em;
      background-color: CanvasText;
    }
    &:checked {
      @apply before:scale-100 border-[0.2em];
    }
  }
}
// .base-radio-form-control {
//   @apply grid gap-2;
//   grid-template-columns: 1em auto;
//   input[type="radio"] {
//     @apply before:box-border after:box-border appearance-none bg-white m-0 text-black w-6 h-6 border-[0.15em] transition transform
//    border-solid border-black rounded-full grid place-content-center;
//     &::before {
//       content: "";
//       @apply w-[0.7em] h-[0.7em] rounded-full scale-0 transition transform shadow-black;
//       box-shadow: inset 1em 1em;
//       background-color: CanvasText;
//     }
//     &:checked {
//       @apply before:scale-100 border-[0.2em];
//     }
//   }
// }

.checkbox-form-control {
  @apply grid items-center justify-center;
  input[type="checkbox"] {
    @apply appearance-none m-0 bg-transparent text-white w-5 h-5  border-[0.15em] border-solid rounded-md 
    border-primary grid place-content-center transition transform;
    &::before {
      content: "";
      @apply w-[0.7em] h-[0.7em] scale-0 transition transform shadow-primary;
      box-shadow: inset 1em 1em;
      background-color: CanvasText;
      transform-origin: bottom left;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }
    &:checked {
      @apply before:scale-100 bg-primary;
    }
  }
}
