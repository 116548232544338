@font-face {
    font-family: 'Brandon Grotesque';
    src: url('./medium/BrandonGrotesque-Medium.woff2') format('woff2'),
        url('./medium/BrandonGrotesque-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('./black/BrandonGrotesque-Black.woff2') format('woff2'),
        url('./black/BrandonGrotesque-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('./light/BrandonGrotesque-Light.woff2') format('woff2'),
        url('./light/BrandonGrotesque-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('./bold/BrandonGrotesque-Bold.woff2') format('woff2'),
        url('./bold/BrandonGrotesque-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('./thin/BrandonGrotesque-Thin.woff2') format('woff2'),
        url('./thin/BrandonGrotesque-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: url('./regular/BrandonGrotesque-Regular.woff2') format('woff2'),
        url('./regular/BrandonGrotesque-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
