// .button {
//   //styling general para todos los botones
//   @apply cursor-pointer rounded-[4rem] px-4 py-2;
//   &.scale {
//     //aplicar scaling al hacer hover
//     @apply transition-all ease-in hover:scale-110;
//   }

//   &.disabled {
//     @apply opacity-50;
//   }
// }
.button {
  @apply rounded-xl px-2 py-3 text-white font-bold;
  &:disabled {
    @apply opacity-30;
  }
  &.circle--icon {
    @apply rounded-full flex items-center justify-center p-6 text-white;
    i {
      @apply text-xl;
    }
    &.small {
      @apply p-3;
    }
  }
}
