@font-face {
  font-family: "Flame";
  src: url("./regular/FlameRegular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Flame";
  src: url("./bold/FlameBold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Flame";
  src: url("./sans/FlameSans.otf") format("opentype");
  font-weight: lighter;
  font-style: normal;
  font-display: swap;
}
