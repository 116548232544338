.layout {
  @apply flex flex-col real-h w-full overflow-hidden pt-[75px] lg:pt-[100px]  lg:pb-[28px] pb-[0px] relative;
}
.page {
  @apply flex flex-col h-full w-full overflow-hidden;
}
section {
  @apply w-full flex-1 flex;
}
.card {
  @apply rounded-lg w-full shadow-2xl shadow-gray-800 flex flex-col p-5;
}

.sheet {
  @apply rounded-t-lg rounded-b-none w-full shadow-2xl shadow-gray-800 flex flex-col p-4;
}

.product {
  @apply rounded-xl bg-secondary w-full shadow-2xl shadow-gray-800 flex items-center justify-center;
}
.bread-crumb {
  @apply flex gap-3 items-center w-full;
}
