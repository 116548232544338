@font-face {
  font-family: "Futura";
  src: url("./light/Futura-Light-font.ttf") format("opentype");
  font-weight: lighter;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura";
  src: url("./bold/Futura-Bold-font.ttf") format("opentype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Futura";
  src: url("./extra_black/Futura-Extra-Black-font.ttf") format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Futura";
  src: url("./regular/futur.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Futura";
  src: url("./italic/Futura-Light-Italic-font.ttf") format("opentype");
  font-weight: lighter;
  font-style: italic;
  font-display: swap;
}
